/***************************************************************************************************
 * 
 * This file defines the Redux slice for managing user-related actions, including:
 * 
 * 1. Adding, updating, and deleting users.
 * 2. Activating and deactivating user accounts.
 * 3. Fetching all users from the backend.
 * 4. Checking if users have associated quotations or revisions.
 * 
***************************************************************************************************/

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  createUser,
  getUsers,
  updateUser,
  deleteUser,
  desactivateUser,
  checkUserQuotationAndRevisions,
  reactivateUser,
  updateUserOffice
} from "src/_helpers/api";

// create slice

const name = "users";

const initialState = createInitialState();

// Define an async thunk for the login action
const addUserAsync = createAsyncThunk(`${name}/add-user`, async (userData) => {
  // Send a UPDATE request to the authenticate endpoint
  //console.log('🚀 ~ addUserAsync ~ userData:', userData)
  let response;
  if (userData.id) {
    response = await updateUser(userData);
  } else {
    response = await createUser(userData);
  }
  return response;
});

const getAllUsersAsync = createAsyncThunk(`${name}/get-users`, async () => {
  const response = await getUsers();
  //console.log('🚀 ~ returncreateAsyncThunk ~ response:', response)
  return response;
});

const desactivateUserAsync = createAsyncThunk(
  `${name}/desactivate-user`,
  async (id) => {
    const response = await desactivateUser(id);
    //console.log('🚀 ~ returnDesactivateAsyncThunk ~ response:', response)
    return response;
  },
);

////////// AJOUT //////////
const reactivateUserAsync = createAsyncThunk(
  `${name}/reactivate-user`,
  async (id) => {
    const response = await reactivateUser(id);
    //console.log('🚀 ~ returnReactivateAsyncThunk ~ response:', response)
    return response;
  },
);

const checkUserQuotationAndRevisionsAsync = createAsyncThunk(
  `${name}/checkUserQuotationAndRevisions-user`,
  async (id) => {
    try {
      const response = await checkUserQuotationAndRevisions(id);
      //console.log('🚀 ~ returncheckUserQuotationAndRevisionsAsyncThunk ~ response:', response);
      return response;
    } catch (error) {
      //console.log('🚀 ~ checkUserQuotationAndRevisionsAsync Error:', error);
      throw error;
    }
  },
);

////////// AJOUT //////////
const deleteUserAsync = createAsyncThunk(`${name}/delete-user`, async (id) => {
  const response = await deleteUser(id);
  //console.log('🚀 ~ returnDeleteAsyncThunk ~ response:', response)
  return response;
});

////////// AJOUT //////////
const updateUserOfficeAsync = createAsyncThunk(
  `${name}/update-user-office`,
  async ({ userId, officeName }) => {
    const response = await updateUserOffice(userId, officeName);
    return response; // L'API retourne une réponse contenant l'utilisateur mis à jour
  }
);

const userSlice = createSlice({
  name,
  initialState: createInitialState(), 
  reducers: {
    resetState: (state) => {
      state.users = []; 
      state.error = null; 
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(addUserAsync.pending, (state) => {
        state.error = null;
      })
      .addCase(addUserAsync.fulfilled, (state, action) => {
        state.user = action.payload;
        //console.log('🚀 ~ addUserAsync.fulfilled ~ action:', action)
      })
      .addCase(addUserAsync.rejected, (state, action) => {
        state.error = action.error;
      })

      .addCase(getAllUsersAsync.pending, (state) => {
        state.error = null;
      })
      .addCase(getAllUsersAsync.fulfilled, (state, action) => {
        state.users = action.payload.data || [];
        //console.log('🚀 ~ getAllUsersAsync.fulfilled ~ action:', action)
      })
      .addCase(getAllUsersAsync.rejected, (state, action) => {
        state.error = action.error;
      })

      .addCase(desactivateUserAsync.pending, (state) => {
        state.error = null;
      })
      .addCase(desactivateUserAsync.fulfilled, (state, action) => {
        state.users = action.payload.data;
        //console.log('🚀 ~ desactivateUserAsync.fulfilled ~ action:', action)
      })
      .addCase(desactivateUserAsync.rejected, (state, action) => {
        state.error = action.error;
      })

      .addCase(reactivateUserAsync.pending, (state) => {
        state.error = null;
      })
      .addCase(reactivateUserAsync.fulfilled, (state, action) => {
        state.users = action.payload.data;
        //console.log('🚀 ~ reactivateUserAsync.fulfilled ~ action:', action)
      })
      .addCase(reactivateUserAsync.rejected, (state, action) => {
        state.error = action.error;
      })

      .addCase(checkUserQuotationAndRevisionsAsync.pending, (state) => {
        state.error = null;
      })
      .addCase(
        checkUserQuotationAndRevisionsAsync.fulfilled,
        (state, action) => {
          state.users = action.payload.data || state.users;
          //console.log('🚀 ~ checkUserQuotationAndRevisionsAsync.fulfilled ~ action:', action)
        },
      )
      .addCase(
        checkUserQuotationAndRevisionsAsync.rejected,
        (state, action) => {
          state.error = action.error;
        },
      )
      .addCase(deleteUserAsync.pending, (state) => {
        state.error = null;
      })
      .addCase(deleteUserAsync.fulfilled, (state, action) => {
        state.users = action.payload.data;
        //console.log('🚀 ~ deleteUserAsync.fulfilled ~ action:', action)
      })
      .addCase(deleteUserAsync.rejected, (state, action) => {
        state.error = action.error;
      })
      .addCase(updateUserOfficeAsync.pending, (state) => {
        state.error = null;
      })
      .addCase(updateUserOfficeAsync.fulfilled, (state, action) => {
        const updatedUser = action.payload;
        state.users = state.users.map((user) =>
          user.id === updatedUser.id ? { ...user, office: updatedUser.office } : user
        );
      })
      .addCase(updateUserOfficeAsync.rejected, (state, action) => {
        state.error = action.error;
      });
  },
});

function createInitialState() {
  return {
    users: [],
  };
}

export const userActions = {
  ...userSlice.actions,
  getAllUsersAsync,
  addUserAsync,
  deleteUserAsync,
  desactivateUserAsync,
  reactivateUserAsync,
  checkUserQuotationAndRevisionsAsync,
  updateUserOfficeAsync,
  resetState: userSlice.actions.resetState,}

export const usersReducer = userSlice.reducer;
