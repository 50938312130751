/***************************************************************************************************
*
* This file handles office-related actions and state using Redux Toolkit.
* 
* It allows:
* 
* 1. Asynchronous actions to add and fetch offices via API calls.
* 2. Managing the Redux state for offices, including error handling and resetting state.
* 3. Centralized control of the list of offices, enabling components to access and update office data.
* 
* Redux Toolkit simplifies Redux by combining actions, reducers, and async logic in one structure.
*
***************************************************************************************************/

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getOffices, createOffice, deleteOffice, updateOffice } from 'src/_helpers/api';

const name = 'offices';

const initialState = createInitialState();

const getAllOfficesAsync = createAsyncThunk(`${name}/get-offices`, async () => {
  return await getOffices();
});

const addOfficeAsync = createAsyncThunk(`${name}/add-office`, async (office, { rejectWithValue }) => {
  try {
    const response = await createOffice(office);
    return response.data; // Retourne les données du bureau ajouté
  } catch (error) {
    return rejectWithValue(error.response?.data || error.message);
  }
});

const updateOfficeAsync = createAsyncThunk(`${name}/update-office`, async (office, { rejectWithValue }) => {
  try {
    const response = await updateOffice(office);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || error.message);
  }
});

const deleteOfficeAsync = createAsyncThunk(`${name}/delete-office`, async (officeId) => {
  return await deleteOffice(officeId);
});


const officeSlice = createSlice({
  name,
  initialState: createInitialState(),
  reducers: {
    resetState: (state) => {
      state.offices = [];
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addOfficeAsync.pending, (state) => {
        state.error = null;
      })
      .addCase(addOfficeAsync.fulfilled, (state, action) => {
        state.offices.push(action.payload);
      })
      .addCase(addOfficeAsync.rejected, (state, action) => {
        state.error = action.error;
      })
      .addCase(getAllOfficesAsync.pending, (state) => {
        state.error = null;
      })
      .addCase(getAllOfficesAsync.fulfilled, (state, action) => {
        state.offices = action.payload.data || [];
      })
      .addCase(getAllOfficesAsync.rejected, (state, action) => {
        state.error = action.error;
      })
      .addCase(updateOfficeAsync.pending, (state) => {
        state.error = null;
      })
      .addCase(updateOfficeAsync.fulfilled, (state, action) => {
        const updatedOffice = action.payload;
      
        if (!updatedOffice || !updatedOffice.id) {
          //console.error("Invalid payload received in updateOfficeAsync.fulfilled:", action.payload);
          return;
        }
      
        const index = state.offices.findIndex((office) => office.id === updatedOffice.id);
        if (index !== -1) {
          state.offices[index] = updatedOffice;
        }
      })
      
      .addCase(updateOfficeAsync.rejected, (state, action) => {
        state.error = action.error;
      })
      .addCase(deleteOfficeAsync.pending, (state) => {
        state.error = null;
      })
      .addCase(deleteOfficeAsync.fulfilled, (state, action) => {
        const deletedOfficeId = action.payload.id;
        state.offices = state.offices.filter((office) => office.id !== deletedOfficeId);
      })
      .addCase(deleteOfficeAsync.rejected, (state, action) => {
        state.error = action.error;
      });
      
  },
});

function createInitialState() {
  return {
    offices: [],
    error: null,
  };
}

export const officeActions = {
  ...officeSlice.actions,
  getAllOfficesAsync,
  addOfficeAsync,
  updateOfficeAsync,
  deleteOfficeAsync,
  resetState: officeSlice.actions.resetState,
};

export const officesReducer = officeSlice.reducer;
